<template>
  <div
    id="SuccessfullySavedToast"
    class="toast align-items-center border-1 border-success"
    role="alert"
    aria-live="assertive"
    aria-atomic="true"
  >
    <div class="d-flex">
      <div class="toast-body text-success">Data Saved!</div>
      <button
        type="button"
        class="btn-close me-2 m-auto"
        data-bs-dismiss="toast"
        aria-label="Close"
      ></button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SavedToast',
  components: {},
  data() {
    return {}
  },

  methods: {},
}
</script>

<style lang="scss" scoped></style>
