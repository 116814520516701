<template>
  <div>
    <div v-if="pageReady" class="d-flex justify-content-center">
      <div class="container mx-5 px-5">
        <div class="row d-flex mx-auto">
          <ul
            id="pills-tab"
            class="nav nav-pills d-flex impairex-tabs justify-content-between flex-wrap p-0 overflow-hidden"
            role="tablist"
          >
            <li
              v-for="(item, index) in renderTabs"
              :key="item.title"
              class="nav-item mb-3"
              role="presentation"
            >
              <button
                :id="item.title"
                :key="index"
                class="btn nav-link bold text-capitalize"
                :class="index === 0 ? 'active' : ''"
                autocomplete="off"
                data-bs-toggle="pill"
                type="button"
                role="tab"
                aria-selected="true"
                @click.prevent="renderComponent({ ...item, index: index })"
              >
                {{ item.title }}
              </button>
            </li>
          </ul>
        </div>

        <div v-if="compReady" id="pills-tabContent" class="mt-5 tab-content">
          <div v-if="!goToNext && currentTabIndex == 0" class="text-center">
            Please select or create basic patient details.
          </div>
          <div class="tab-pane show active" role="tabpanel">
            <component
              :is="componentName"
              v-model:ready-next="readyNext"
              v-model:toast-text="toastText"
              :selected-part="comp.title"
              :api-post-obj="putApiAssessmentObjs"
              :old-assessment-data="assessmentData"
              :assessment-id="assessmentID"
              :existing-table-data="tableData"
              @remove-body-part="patchBodyPartList"
            />
          </div>
        </div>

        <div
          v-else
          class="min-vh-100 d-flex justify-content-center align-items-center"
        >
          <div>
            <div
              class="spinner-border text-primary"
              style="width: 12rem; height: 12rem"
              role="status"
            >
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-else
      class="min-vh-100 d-flex justify-content-center align-items-center"
    >
      <div>
        <div
          class="spinner-border text-primary"
          style="width: 12rem; height: 12rem"
          role="status"
        >
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>

    <!-- Toast Notifications -->
    <div
      id="toastContainer"
      class="toast-container m-1 position-fixed bottom-0 end-0 p-3"
    >
      <!-- to be cloned -->
      <div
        id="basicToast"
        class="toast align-items-center border-1 border-primary"
        role="alert"
        aria-live="assertive"
        aria-atomic="true"
      >
        <div class="d-flex">
          <!-- insert text by dom manipulation -->
          <div class="toast-body">...</div>

          <button
            type="button"
            class="btn-close me-2 m-auto"
            data-bs-dismiss="toast"
            aria-label="Close"
          />
        </div>
      </div>

      <SavedToast />

      <!-- ensure this computed property runs -->
      {{ notificationDetection }}
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'

import { Toast } from 'bootstrap'
import SavedToast from '@/components/Widget/SavedToast'

export default {
  name: 'AssessmentTemplate',
  components: {
    Toast,
    SavedToast,
    UpperBodyParts: defineAsyncComponent(() =>
      import(`@/views/BodyPart/UpperBodyParts.vue`)
    ),
    LumbarSpine: defineAsyncComponent(() =>
      import(`@/views/BodyPart/Spines/LumbarSpine.vue`)
    ),
    ThoracicSpine: defineAsyncComponent(() =>
      import(`@/views/BodyPart/Spines/ThoracicSpine.vue`)
    ),
    CervicalSpine: defineAsyncComponent(() =>
      import(`@/views/BodyPart/Spines/CervicalSpine.vue`)
    ),
    Fingers: defineAsyncComponent(() => import(`@/views/BodyPart/Fingers.vue`)),
    AdminForm: defineAsyncComponent(() =>
      import(`@/components/Form/AdminForm.vue`)
    ),
    NurseForm: defineAsyncComponent(() =>
      import(`@/components/Form/NurseForm.vue`)
    ),
    DoctorForm: defineAsyncComponent(() =>
      import(`@/components/Form/DoctorForm.vue`)
    ),
    Report: defineAsyncComponent(() =>
      import(`@/views/Report/RtwsaReport.vue`)
    ),
    Summary: defineAsyncComponent(() =>
      import(`@/views/Report/AssessmentSummary.vue`)
    ),
  },

  data() {
    return {
      pageReady: false,
      compReady: false,
      assessmentData: {},
      renderTabs: [
        { title: 'Admin', compName: 'AdminForm' },
        { title: 'Nurse', compName: 'NurseForm' },
        { title: 'Doctor', compName: 'DoctorForm' },
      ],
      assessmentID: 0,
      componentName: null,
      comp: {
        title: null,
      },
      assessedParts: [],
      upperParts: ['shoulder', 'wrist', 'finger_thumb', 'elbow'],

      fingerParts: [
        'finger_index',
        'finger_middle',
        'finger_ring',
        'finger_pinky',
      ],
      tableParts: {},
      tableData: null,
      putApiAssessmentObjs: null,
      listOfBodyPart: [],
      selectedBodyParts: [],
      //form will update this based on api calls
      toastText: null,

      readyNext: '',
      currentTabIndex: 0,
    }
  },

  computed: {
    goToNext: function () {
      if (this.readyNext) {
        this.isNext()
        return true
      } else return false
    },

    notificationDetection: function () {
      if (this.toastText) {
        this.renderNoti(this.toastText)
      }
      return ''
    },
  },

  async mounted() {
    //force clear bootstrap tooltip that appear on hover during page transition
    let tooltips = document.querySelectorAll('[role="tooltip"]')
    tooltips.forEach((e) => {
      e.remove()
    })

    this.assessmentID = parseInt(this.$route.params.id)

    //load data from selected assessment lists if none, load new from ID

    await this.loadValueFromAssessmentTable()

    //reduce selected body parts
    if (this.assessmentData) {
      let data = this.assessmentData.body_parts

      // Ensure the body_parts array is sorted in ascending order by ID ,
      // This is because the backend will always output,
      // same element's name side by side before the next,
      // Array needs to look like [ a , a , b , b ]

      data.sort((a, b) => {
        if (a.id > b.id) return 1
        if (a.id < b.id) return -1
        return 0
      })

      let filterArray = []

      //this reduced non selected and if two parts r selected and have same name
      data.reduce((prev, current) => {
        if (prev && prev.is_selected) {
          if (current.is_selected && prev.name === current.name) {
            //because the filtered array will always have the last obj as prev , so if we found same name, remove , edit and push again
            filterArray.pop()
            // If plural, add 's' behind body part
            filterArray.push({
              title: 'Left & Right ' + prev.display_name + 's',
              direction: ['L', 'R'],
              is_selected: true,
              display_name: prev.display_name,
              name: prev.name,
            })

            return null
          } else {
            current.is_selected
              ? filterArray.push({
                  title:
                    (current.direction === 'L'
                      ? 'Left '
                      : current.direction === 'R'
                      ? 'Right '
                      : '') + current.display_name,
                  direction: current.direction ? [current.direction] : null,
                  is_selected: true,
                  display_name: current.display_name,
                  name: current.name,
                })
              : ''
            return current
          }
        } else {
          current.is_selected
            ? filterArray.push({
                title:
                  (current.direction === 'L'
                    ? 'Left '
                    : current.direction === 'R'
                    ? 'Right '
                    : '') + current.display_name,
                direction: current.direction ? [current.direction] : null,
                is_selected: true,
                display_name: current.display_name,
                name: current.name,
              })
            : ''

          return current
        }
      }, null)

      this.selectedBodyParts = filterArray
      //combine filtered bodypart array for sub-navbar
      this.renderTabs = this.renderTabs.concat(filterArray)
    }

    //always display patient basic info form first
    this.componentName = 'AdminForm'

    // summary report as last tab
    this.renderTabs.push(
      { title: 'RTWSA Report', compName: 'Report' },
      {
        title: 'Assessment Summary',
        compName: 'Summary',
      }
    )

    this.compReady = true
    this.pageReady = true
  },

  methods: {
    renderNoti(toastObj) {
      //initialize saved toast component
      let SuccessfullySavedToast = document.getElementById(
        'SuccessfullySavedToast'
      )
      let savedBootstrapToast = new Toast(SuccessfullySavedToast)

      const referenceToast = document.getElementById('basicToast')
      const newToast = referenceToast.cloneNode(true)

      newToast.id = toastObj.id

      newToast.getElementsByClassName('toast-body')[0].innerText = toastObj.text

      document.getElementById('toastContainer').appendChild(newToast)

      let initializeThis = document.getElementById(toastObj.id)

      let newBootstrapToast = new Toast(initializeThis)

      initializeThis.addEventListener('hidden.bs.toast', function () {
        initializeThis.remove()
      })

      newBootstrapToast.show()

      savedBootstrapToast.show()
    },

    async getBodyPartList() {
      await this.$store
        .dispatch('getBodyPartsList')
        .then((response) => {
          this.listOfBodyPart = response.data
        })
        .catch((error) => {
          console.log(error)
        })
    },

    async patchBodyPartList(arrayOfBodyPart) {
      let output = { id: this.assessmentID, payload: [] }
      // Payload accepts [ { body_part: 0, directions: []} ]

      // Payload array only takes body part that aren't to be removed

      this.selectedBodyParts.forEach((x) => {
        let found = arrayOfBodyPart.find((e) => e.name === x.name)

        // Push body parts aren't selected to be removed
        if (!found) {
          output.payload.push({
            name: x.name,
            body_part: null,
            directions: x.direction,
          })
        } else if (x.direction.length === 2 && found.directions.length !== 2) {
          // If body part has both side selected,
          // Push the side that user did not select to be removed

          output.payload.push({
            name: x.name,
            body_part: null,
            directions: x.direction.filter(
              (d) => !found.directions.includes(d)
            ),
          })
        }

        // If two side are selected to remove
        // Don't have to push to array
      })

      await this.getBodyPartList()

      // Map body_part ID onto payload array elements

      this.listOfBodyPart.forEach((e) => {
        let found = output.payload.find((x) => x.name === e.name)
        console.log(e)
        if (found) {
          found['body_part'] = e.id

          console.log(found)
        }
      })

      await this.$store
        .dispatch(`patchAssessmentBodyParts`, output)
        .then(() => {
          // Trigger toast text
          this.$root.toasts.push({
            status: 'success',
            content: `Successfully removed body part.`,
          })
          location.reload()
        })
        .catch((error) => {
          console.log(error)
          // Trigger toast text
          this.$root.toasts.push({
            status: 'danger',
            content: 'Something went wrong, please inform the development team. '`ERROR: ${error}`,
          })
        })
    },
    async renderComponent(x) {
      let result
      this.compReady = false
      this.comp.title = x.display_name
      this.currentTabIndex = x.index

      if (this.upperParts.includes(x.name)) {
        await this.getOptions(x)
        result = 'UpperBodyParts'
      } else if (this.fingerParts.includes(x.name)) {
        await this.getOptions(x)
        result = 'Fingers'
      } else if (x.name === 'spine_lumbar') {
        result = 'LumbarSpine'

        await this.getOptions(x)
      } else if (x.name === 'spine_cervical') {
        result = 'CervicalSpine'

        await this.getOptions(x)
      } else if (x.name === 'spine_thoracic') {
        result = 'ThoracicSpine'

        await this.getOptions(x)
      } else {
        result = x.compName
      }

      this.componentName = result
      this.compReady = true
    },

    // get bodypart options and data
    getOptions(item) {
      let bodyPartDegreesData =
        this.$store.state.assessmentData.body_part_degrees

      let Ary = []
      Ary = this.$store.state.assessmentData.body_parts.filter(
        (x) => x.display_name == item.display_name
      )

      //if ary only contains 1 obj then submit as obj instead
      Ary.length === 1
        ? (this.putApiAssessmentObjs = Ary[0])
        : (this.putApiAssessmentObjs = Ary)

      return (this.tableData = {
        direction: item.direction,
        ...bodyPartDegreesData.find((x) => x.name == item.name),
      })
    },

    //auto press next tab
    isNext() {
      if (this.readyNext) {
        let nextIdx = this.currentTabIndex + 1

        document.getElementById(this.renderTabs[nextIdx].title).click()

        return (this.readyNext = false)
      }
    },

    async loadValueFromAssessmentTable() {
      await this.$store
        .dispatch(`getAssessment`, { id: this.assessmentID })
        .then((response) => {
          this.assessmentData = response.data
          this.$store.commit('STORE_ASSESSMENTDATA', response.data)

          this.$store.commit('STORE_PATIENTID', response.data.patient.id)
          this.$store.commit('STORE_REFID', response.data.referral.id)

          this.pageReady = true
        })
        .catch((error) => {
          this.pageReady = true
          console.log(error)
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.dropdown-menu {
  z-index: 999;
}

.impairex-tabs {
  .nav-item {
    position: relative;

    &:not(:last-child) {
      &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        border-top: 2px solid #32c3d6;
        background: none;
        width: 575%;
        max-width: 500px;
        z-index: -999;
        transform: translateY(-50%);
      }

      &::before {
        content: '';
        position: absolute;
        top: 50%;
        right: 0;
        border-top: 2px solid #32c3d6;
        background: none;
        width: 575%;
        max-width: 500px;
        z-index: -999;
        transform: translateY(-50%);
      }
    }

    &:last-child {
      &::after {
        content: '';
        position: absolute;
        top: 50%;
        right: 0;
        border-top: 2px solid #32c3d6;
        background: none;
        width: 800%;
        max-width: 700px;
        z-index: -999;
        transform: translateY(-50%);
      }

      button {
        float: right;
        position: relative;

        &::after {
          content: '';
          position: absolute;
          top: 33%;
          left: -4%;
          width: 0;
          height: 0;
          border-top: 6px solid transparent;
          border-bottom: 6px solid transparent;

          border-left: 7px solid #32c3d6;
        }
      }
    }

    &:not(:first-child) {
      margin-left: 4rem;
    }

    button {
      border: 1px solid #32c3d6;
      border-radius: 0;
      background-color: white;

      &:disabled {
        background-color: gray;
        border: none;
        opacity: 100;
        color: white;
      }
    }
  }
}
</style>
